import tags from '@/constants/queryKeys/tags'
import users from '@/constants/queryKeys/users'
import userFields from '@/constants/queryKeys/userFields'
import preferences from '@/constants/queryKeys/preferences'

export default {
    preferences,
    tags,
    userFields,
    users
}
